// This is the template for each programmatically generated item in the shop. It will be populated with data from markdown files in the content folder.

import React from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import styled from "styled-components"
import Layout from "../components/layout";

const Heading = styled.h1`
  font-size: 1.5em;
  color:#313E48;
  margin: 20px 0px 0px 20px;
  padding: 10px;
  font-weight: 700;


`
const Wrapper = styled.div`
  background-color: #E7E5DF;
  padding: 20px;


  `

const ImgStyled = styled(Img)`
  width: 350px;
  background-color: #CBCBD4;
  margin: 20px 0px 0px 20px;



  `

const Price = styled.span`
  margin: 20px 0px 0px 20px;
  padding: 10px;
  font-weight: 700;
  color:#313E48;

`

const Description = styled.p`
  margin: 20px 0px 0px 20px;
  padding: 10px;
  color:#313E48;
  max-width:350px;
  line height:100px;

`

const Dropdown = styled.select`
  margin: 20px 0px 0px 30px;
  padding: 10px;
  font-weight: 700;
  border: none;
  outline: none;
`
const DropdownOption = styled.option`
  margin: 20px 0px 0px 20px;
  padding: 10px;
  background: ${props => props.theme.colors.secondaryAccent};
  font-weight: 700;
  border: none;
  outline: none;
`

const BuyButton = styled.button`
  margin: 20px 0px 50px 20px;
  padding: 20px;
  background: ${props => props.theme.colors.secondaryAccent};
  font-weight: 700;
`

class Item extends React.Component {
  state = {
    selected: this.props.data.markdownRemark.frontmatter.customField.values[0].name
  }

  setSelected = (value) => {
    this.setState({ selected: value })
  }

  // create the string required by snipcart to allow price changes based on option chosen
  createString = (values) => {
    return values.map(option => {
      const price = option.priceChange >= 0 ? `[+${option.priceChange}]` : `[${option.priceChange}]`
      return `${option.name}${price}`
    }).join('|')
  }

   // calculate price based on option selected for display on item page
  updatePrice = (basePrice, values) => {
    const selectedOption = values.find(option => option.name === this.state.selected)
    return (basePrice + selectedOption.priceChange).toFixed(2)

  }

  render() {
    const item = this.props.data.markdownRemark
    const siteTitle = this.props.data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
<Wrapper> 
        <ImgStyled fluid={item.frontmatter.image.childImageSharp.fluid} />
        <Heading>{item.frontmatter.title}</Heading>
        <Dropdown
          id={item.frontmatter.customField.name}
          onChange={(e) => this.setSelected(e.target.value)}
          value={this.state.selected}> Size
          {item.frontmatter.customField.values.map((option) => (<DropdownOption key={option.name}>{option.name}</DropdownOption>))}
        </Dropdown>
        <Price>${this.updatePrice(item.frontmatter.price, item.frontmatter.customField.values)}</Price>
       
       {/*

        <Dropdown
          id={item.frontmatter.customField2.name}
          onChange={(e) => this.setSelected(e.target.value)}
          value={this.state.selected}> Grind
          {item.frontmatter.customField2.values.map((option) => (<DropdownOption key={option.name}>{option.name}</DropdownOption>))}
        </Dropdown>
        
        */} 

        <Description>{item.frontmatter.longDescription}</Description>


        <BuyButton
          className='snipcart-add-item'
          data-item-id={item.frontmatter.id}
          data-item-price={item.frontmatter.price}
          data-item-name={item.frontmatter.title}
          data-item-description={item.frontmatter.shortDescription}
          data-item-image={item.frontmatter.image.childImageSharp.fluid.src}
          data-item-url={"https://jbcoffee.com.au" + item.fields.slug} //REPLACE WITH OWN URL
          data-item-custom1-name={item.frontmatter.customField ? item.frontmatter.customField.name : null}
          data-item-custom1-options={this.createString(item.frontmatter.customField.values)}
          data-item-custom1-value={this.state.selected}
          data-item-custom2-name="Grind"
          data-item-custom2-options="Wholebeans|Turkish|Espresso|Stovetop|Aeropress|Filter|Mokamaster|French|Cold brew">

        {/*
          data-item-custom2-name={item.frontmatter.customField ? item.frontmatter.customField2.name : null}
          data-item-custom2-options={this.createString(item.frontmatter.customField2.values)}
          data-item-custom2-value={this.state.selected}
        */} 
         
          Add to cart
        </BuyButton>
</Wrapper>
      </Layout>
    )
  }
}

export default Item;

export const pageQuery = graphql`
  query ItemBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      fields {
        slug
      }
      frontmatter {
        title
        shortDescription
        longDescription
        price
        id
        image {
          childImageSharp {
            fluid {
              src
              ...GatsbyImageSharpFluid
            }
          }
        }
        customField {
          name
          values {
            name
            priceChange
          }
        }
      }
    }
  }
`
